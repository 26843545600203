import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useCommonStore } from './common'
import { convertToTree } from '@/utils/helper'

export const useUserStore = defineStore('user', () => {
    const commonStore = useCommonStore()

    // state
    const isLogin = ref(false)
    const userId = ref(null)
    const role = ref(null)
    const permissionList = ref([])
    const menuPermission = ref([]) // router_name item
    const sideMenu = ref([]) // side panel menu

    // actions
    function logout() {
        isLogin.value = false
        localStorage.removeItem('user-token')
        // localStorage.removeItem('user-id')
        commonStore.createToast('Logout', 'Successfully Logout')
        this.$router.push({ name: 'Login' })
    }
    function setUserId(id) {
        userId.value = id
    }
    function authLogin(data) {
        if (data.code === 0) {
            this.$router.push({ name: 'WelcomeChild' })
            isLogin.value = true
            //set user permission after login
            role.value = data.user.role
            permissionList.value = data.user.userPermissionList
            const routerNameArr = []
            const menuList = data.user.menu.filter((value) => {
                routerNameArr.push(value.route_name)
                return value.show === 1
            })
            sideMenu.value = convertToTree(menuList)
            menuPermission.value = routerNameArr
            
            commonStore.createToast('Login Success', 'You are successfully login')
            return
        }
    }

    const setAuth = ({ userRole, userPermissionList,menu }) => {
        isLogin.value = true
        role.value = userRole
        permissionList.value = userPermissionList
        const routerNameArr = []
        const menuList = menu.filter((value) => {
            routerNameArr.push(value.route_name)
            return value.show === 1
        })
        sideMenu.value = convertToTree(menuList)
        
        menuPermission.value = routerNameArr
    }

    return {
        isLogin,
        logout,
        setUserId,
        authLogin,
        setAuth,
        role,
        permissionList,
        sideMenu,
        menuPermission
    }
})
