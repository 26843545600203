export const HCAPTCHA_SITE_KEY =
    process.env.VUE_APP_HCAPTCHA_SITE_KEY || '10000000-ffff-ffff-ffff-000000000001'
export const API_KEY = process.env.VUE_APP_API_KEY || 'abc1234!@#$'
export const LOCAL_ENV = process.env.VUE_APP_LOCAL_ENV || false

export default {
    HCAPTCHA_SITE_KEY,
    API_KEY,
    LOCAL_ENV,
}
