// import { h, resolveComponent } from "vue";
import { createRouter, createWebHistory } from 'vue-router'
// import jwt from "jsonwebtoken";
import { checkAuthentication } from '@/api/user'

import BlankLayout from '@/layouts/BlankLayout'
import GeneralLayout from '@/layouts/GeneralLayout'
import EventLayout from '@/layouts/EventLayout'
import { useCommonStore } from '@/stores/common'
import SampleIframe from '@/views/pages/sampleIframe/SamplePage'
import { USER_ROLE } from '@/utils/enum'
import { useUserStore } from '@/stores/user'

const routes = [
    {
        path: '/',
        name: 'Pages',
        meta: { requiresAuth: false },
        component: BlankLayout,
        children: [
            {
                path: '',
                name: 'Landing',
                redirect: '/welcome',
            },
            {
                path: '404',
                name: 'Page404',
                component: () => import('@/views/pages/error/Page404'),
            },
            {
                path: '500',
                name: 'Page500',
                component: () => import('@/views/pages/error/Page500'),
            },
            {
                path: 'login',
                name: 'Login',
                component: () => import('@/views/pages/auth/Login'),
            },
            {
                path: 'noPermission',
                name: 'noPermission',
                component: () => import('@/views/pages/noPermiss'),
            },
        ],
    },
    {
        path: '/country',
        name: 'Country',
        meta: { requiresAuth: true, role: USER_ROLE.ADMIN },
        component: GeneralLayout,
        children: [
            {
                path: 'list',
                name: 'CountryList',
                component: () => import('@/views/pages/country/CountryList.vue'),
            },

            {
                path: 'tag',
                name: 'CountryTag',
                component: () => import('@/views/pages/country/CountryTag.vue'),
            },
        ],
    },

    {
        path: '/publicComponent',
        name: 'Public Component',
        meta: { requiresAuth: true, role: USER_ROLE.ADMIN },
        component: GeneralLayout,
        children: [
            {
                path: 'menu',
                name: 'Menu List',
                component: () => import('@/views/pages/publicModule/MenuList'),
            },
            {
                path: 'menu/menuSecondary',
                name: 'MenuSecondary',
                component: () => import('@/views/pages/publicModule/SecondaryMenu'),
            },
            {
                path: 'socialMedia',
                name: 'Social Media',
                component: () => import('@/views/pages/publicModule/SocialMedia'),
            },
            {
                path: 'compliance',
                name: 'Compliance',
                component: () => import('@/views/pages/publicModule/Compliance'),
            },
            {
                path: 'compliance/complianceSecondary',
                name: 'ComplianceSecondary',
                component: () => import('@/views/pages/publicModule/SecondaryMenu'),
            },
            {
                path: 'dynamicDownloadLink',
                name: 'Download Link',
                component: () => import('@/views/pages/dynamicDownloadLink'),
            },
        ],
    },

    {
        path: '/dashboard',
        name: 'Dashboard',
        meta: { requiresAuth: true },
        component: GeneralLayout,
        children: [
            {
                path: '',
                name: 'Home',
                component: () => import('@/views/pages/dashboard/Home'),
            },
            {
                path: '/entity/:entityId',
                name: 'CategoryList',
                component: () => import('@/views/pages/dashboard/ManageData'),
            },
            {
                path: '/entity/:entityId/category/:categoryId/:dataType',
                name: 'FinancialAssetList',
                component: () => import('@/views/pages/dashboard/ManageData'),
            },
            {
                path: '/entity/:entityId/category/:categoryId/:dataType/:assetId',
                name: 'FinancialToolList',
                component: () => import('@/views/pages/dashboard/ManageData'),
            },
        ],
    },
    {
        path: '/welcome',
        name: 'Welcome',
        meta: { requiresAuth: true },
        component: GeneralLayout,
        props: { isHomePage: true },
        children: [
            {
                path: '',
                name: 'WelcomeChild',
                component: () => import('@/views/pages/welcome')
            }
        ],
    },
    {
        path: '/setting',
        name: 'Setting',
        meta: { requiresAuth: true },
        component: GeneralLayout,
        children: [
            {
                path: 'user',
                name: 'User List',
                component: () => import('@/views/pages/userConfig/index.vue'),
            },
            {
                path: 'systemLogs',
                name: 'Logs List',
                component: () => import('@/views/pages/systemLogs/systemLogsList.vue'),
            },
            {
                path: 'apiconfig',
                name: 'Entities API Configs',
                component: () => import('@/views/pages/apiConfigs/index.vue'),
            },
            {
                path: 'role',
                name: 'Role Configurations',
                component: () => import('@/views/pages/roleConfig/index.vue'),
            },
            // {
            //   path: "/user/:entityId",
            //   name: "user dashboard",
            //   component: () => import("@/views/pages/user"),
            // },
        ],
    },
    // {
    //     path: '/dynamicDownloadLink',
    //     name: 'DynamicDownloadLink',
    //     meta: { requiresAuth: true, role: USER_ROLE.ADMIN },
    //     component: GeneralLayout,
    //     children: [
    //         {
    //             path: '/dynamicDownloadLink',
    //             name: 'tools dashboard',
    //             component: () => import('@/views/pages/dynamicDownloadLink'),
    //         },
    //     ],
    // },
    {
        path: '/sampleIframe',
        name: 'Sample Iframe',
        meta: { requiresAuth: false },
        component: SampleIframe,
    },
    {
        path: '/event',
        name: 'Event',
        meta: { requiresAuth: true, role: USER_ROLE.ADMIN },
        component: EventLayout,
        children: [
            {
                path: '',
                name: 'Event',
                meta: {
                    breadcrumb: [{ name: 'Event Center' }],
                },
                component: () => import('@/views/pages/event/EventTemplateGroup'),
            },
            //TODO dynamic route for event
            //TODO dynamic breadcrumb
            {
                path: '/event/poster_page',
                name: 'Poster Page Listing',
                meta: {
                    breadcrumb: [
                        { name: 'Event Center', href: '/event' },
                        { name: 'Poster Page ' },
                    ],
                },
                component: () => import('@/views/pages/event/EventList'),
            },
            {
                path: '/event/poster_page/create_event',
                name: 'Create Event',
                meta: {
                    breadcrumb: [
                        { name: 'Event Center', href: '/event' },
                        { name: 'Poster Page', href: '/event/poster_page' },
                        { name: 'Create Event' },
                    ],
                },
                component: () => import('@/views/pages/event/CreateEvent'),
            },
            {
                path: '/event/poster_page/edit_event/:event_content_id',
                name: 'Edit Event',
                meta: {
                    breadcrumb: [
                        { name: 'Event Center', href: '/event' },
                        { name: 'Poster Page', href: '/event/poster_page' },
                        { name: 'Edit Event' },
                    ],
                },
                component: () => import('@/views/pages/event/CreateEvent'),
            },
            {
                path: '/event/poster_page/view_event/:event_content_id/',
                name: 'View Event',
                meta: {
                    breadcrumb: [
                        { name: 'Event Center', href: '/event' },
                        { name: 'Poster Page', href: '/event/poster_page' },
                        //TODO update dynamic route on breadcrumb
                        { name: 'View Event' },
                    ],
                },
                component: () => import('@/views/pages/event/CreateEvent'),
            },
            {
                path: '/event/poster_page/create_event/ui_setting',
                name: 'Ui Settings',
                meta: {
                    breadcrumb: [
                        { name: 'Event Center', href: '/event' },
                        { name: 'Poster Page', href: '/event/poster_page' },
                        { name: 'Create Event', href: '/event/poster_page/create_event' },
                        { name: 'Ui Setting' },
                    ],
                },
                component: () => import('@/views/pages/event/UiSetting'),
            },
            {
                path: '/event/poster_page/edit_event/:event_content_id/ui_setting',
                name: 'Edit Ui Settings',
                meta: {
                    breadcrumb: [
                        { name: 'Event Center', href: '/event' },
                        { name: 'Poster Page', href: '/event/poster_page' },
                        //TODO update dynamic route on breadcrumb
                        {
                            name: 'View Event',
                            href: '/event/poster_page/edit_event/:event_content_id',
                        },
                        { name: 'Ui Setting' },
                    ],
                },
                component: () => import('@/views/pages/event/UiSetting'),
            },
            {
                path: '/event/poster_page/view_event/:event_content_id/ui_setting',
                name: 'View Ui Settings',
                meta: {
                    breadcrumb: [
                        { name: 'Event Center', href: '/event' },
                        { name: 'Poster Page', href: '/event/poster_page' },
                        //TODO update dynamic route on breadcrumb
                        {
                            name: 'View Event',
                            href: '/event/poster_page/view_event/:event_content_id',
                        },
                        { name: 'Ui Setting' },
                    ],
                },
                component: () => import('@/views/pages/event/UiSetting'),
            },
        ],
    },
    {
        path: '/dynamic_event',
        name: 'DynamicEvent',
        meta: { requiresAuth: false },
        children: [
            {
                path: '',
                name: 'DynamicEventIndex',
                component: () => import('@/views/dynamicEvent/index'),
            },
            {
                path: ':slug*',
                name: 'DynamicEventLandingBySlug',
                component: () => import('@/views/dynamicEvent/imageLandingBySlug'),
            },
        ],
    },
    {
        path: '/email',
        name: 'Email',
        meta: { requiresAuth: true, role: USER_ROLE.ADMIN },
        component: GeneralLayout,
        children: [
            {
                path: 'emailLogs',
                name: 'Email Logs',
                component: () => import('@/views/pages/email/emailList.vue'),
            },
        ],
    },
    {
        path: '/marketcomponent',
        name: 'Market Component',
        meta: { requiresAuth: true, role: [USER_ROLE.ADMIN, USER_ROLE.EVENT] },
        component: GeneralLayout,
        children: [
            {
                path: 'prize',
                name: 'Event Prizes',
                component: () => import('@/views/pages/marketModule/EventPrizes'),
            },
            {
                path: 'link',
                name: 'Link Shortener',
                meta: { requiresAuth: true, role: USER_ROLE.ADMIN },
                component: () => import('@/views/pages/marketModule/linkShortener'),
            },]
    },
    {
        path: '/eventCenter',
        name: 'Event Center',
        meta: { requiresAuth: true, role: [USER_ROLE.ADMIN, USER_ROLE.EVENT] },
        component: GeneralLayout,
        children: [
            {
                path: 'ibTiers/ibRanking',
                name: 'IB Ranking',
                component: () => import('@/views/pages/eventCenter/ibTiers/ibRanking'),
            },
            {
                path: 'ibTiers/eventTimeline',
                name: 'Event Timeline',
                component: () => import('@/views/pages/eventCenter/ibTiers/eventTimeline'),
            },
            {
                path: 'ibTiers/levelConfig',
                name: 'Level Config',
                component: () => import('@/views/pages/eventCenter/ibTiers/levelConfig'),
            },
            {
                path: 'ibTiers/redemptionItems',
                name: 'Redemption Items',
                component: () => import('@/views/pages/eventCenter/ibTiers/redemptionItems'),
            },
            {
                path: 'ibTiers/userRedemptions',
                name: 'User Redemptions',
                component: () => import('@/views/pages/eventCenter/ibTiers/userRedemptions'),
            },
        ]
    },
    {
        path: '/tools',
        name: 'Tools',
        meta: { requiresAuth: true, role: [USER_ROLE.ADMIN] },
        component: GeneralLayout,
        children: [
            {
                path: 'cookieConsent/scanQueue',
                name: 'Scan Queue',
                component: () => import('@/views/pages/tools/cookieConsent/scanQueue'),
            },
            {
                path: 'cookieConsent/consentHistory',
                name: 'Consent History',
                component: () => import('@/views/pages/tools/cookieConsent/consentHistory'),
            },
            {
                path: 'cookieConsent/cookiesList',
                name: 'Cookies List',
                component: () => import('@/views/pages/tools/cookieConsent/cookiesList'),
            },
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
    },
})

const isLoggedIn = async () => {
    const token = localStorage.getItem('user-token')
    // if token not exist
    if (!token) return false

    // check authentication
    const payload = await checkAuthentication()
    if (payload?.status === 200) {
        const data = useCommonStore()
        const user = useUserStore()

        user.setAuth({
            userRole: payload.data.user.role,
            userPermissionList: payload.data.userPermissionList,
            menu:payload.data.user.menu
        })
        if (payload.data.userPermissionList.length)
            data.setIsTriggerApi(payload.data.userPermissionList.includes('NR'))
        data.setUserID(payload.data.user.id)
        return true
    }

    // if invalid token
    localStorage.removeItem('user-id')
    localStorage.removeItem('user-token')
    return false
}

const whiteList = ['WelcomeChild','Welcome']
router.beforeEach(async (to, from, next) => {
    const userStore = useUserStore()
    const commonStore = useCommonStore()
    if (!userStore.isLogin) await isLoggedIn()
    if (to.meta.requiresAuth) {
        // let permission = false
        // if (userStore.isLogin)
        //     if (to.meta.role) {
        //         //check route whether need role permission
        //         if(typeof (to.meta.role) === 'string'){
        //             if (to.meta.role === userStore.role) permission = true
        //         } else {
        //             if(to.meta.role.includes(userStore.role)) permission = true
        //         }
                
        //         if(permission){
        //             next()
        //         } else {
        //             commonStore.createToast('Unauthorized', 'You are no permission!')
        //             next('/welcome')
        //         }
        //     } else next()
        // else next('/login')
        if (userStore.isLogin){
            if(userStore.menuPermission.includes(to.name) || whiteList.includes(to.name)){
                next()
            } else {
                const ibPath = [
                    '/eventCenter/ibTiers/ibRanking',
                    '/eventCenter/ibTiers/eventTimeline',
                    '/eventCenter/ibTiers/levelConfig',
                    '/eventCenter/ibTiers/redemptionItems',
                    '/eventCenter/ibTiers/userRedemptions'
                ]

                const ccPath = [
                    '/tools/cookieConsent/scanQueue',
                    '/tools/cookieConsent/consentHistory',
                    '/tools/cookieConsent/cookiesList'
                ]

                if(
                    (userStore.menuPermission.includes('IB Tiers') && ibPath.includes(to.path)) || 
                    (userStore.menuPermission.includes('Cookie Consent') && ccPath.includes(to.path))
                ){
                    next()
                }else{
                    commonStore.createToast('Unauthorized', 'You are no permission!')
                    next('/welcome')
                }
            }
        } else {
            next('/login')
        }
        

    } else next()
})

export default router
