import { createApp, markRaw } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import { plugin, defaultConfig } from '@formkit/vue'
import { generateClasses } from '@formkit/themes'
import formKitPlugins from './formkit.config'
// import tailwindFormKitConfig from "../formkit.config"
// import '@formkit/themes/genesis'
// import axiosConfig from './api/config'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'

import axios from 'axios'
import VueAxios from 'vue-axios'

import Vue3EasyDataTable from 'vue3-easy-data-table'
import 'vue3-easy-data-table/dist/style.css'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import Multiselect from '@vueform/multiselect'

import { EditOutlined } from '@ant-design/icons-vue'

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import theme from './theme'

import Pagination from './components/ui/Pagination.vue'
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import { faCaretDown, faPlus } from '@fortawesome/free-solid-svg-icons'

// library.add(faCaretDown,faPlus);

const pinia = createPinia()
pinia.use(({ store }) => {
    store.$router = markRaw(router)
})
const app = createApp(App)
app.use(pinia)
app.use(router)
app.use(CoreuiVue)
app.use(
    plugin,
    defaultConfig({
        config: {
            classes: generateClasses(theme),
        },
        plugins: formKitPlugins,
    }),
)
app.use(VueAxios, axios)
app.use(VueSweetalert2)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
app.component('EasyDataTable', Vue3EasyDataTable)
app.component('VueDatePicker', VueDatePicker)
app.component('MultiSelect', Multiselect)
app.component(EditOutlined.name, EditOutlined)
app.component('CMSPagination',Pagination)
app.use(VueSweetalert2)
app.mount('#app')
