<template>
    <!-- <div class="pagination">
    <select v-model="itemsPerPage" @change="updateItemsPerPage">
      <option v-for="option in itemsPerPageOptions" :value="option.value" :key="option.value">
        {{ option.label }}
      </option>
    </select>
    <button @click="prevPage" :disabled="currentPage === 1">Prev</button>
    <span>Page {{ currentPage }} of {{ totalPages }}</span>
    <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
  </div> -->
    <div class="pagination-cms" ref="target">
        <div class="pagination__rows-per-page">
            rows per page:
            <div class="pagination__rows-selector" style="">
                <div class="rows-input__wrapper" @click="showItemsPerPage = !showItemsPerPage">
                    <div class="rows-input">{{ itemsPerPage }}</div>
                    <div class="triangle"></div>
                </div>
                <ul class="select-items" v-show="showItemsPerPage">
                    <li
                        v-for="item in itemsPerPageOptions"
                        :key="item.value"
                        :class="{ selected: item.value === itemsPerPage }"
                        @click="selectItemsPerPage(item.value)"
                    >
                        {{ item.label }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="pagination__items-index">{{ computedIndex }} of {{ total }}</div>
        <div
            :class="['previous-page__click-button', currentPage === 1 ? 'first-page' : '']"
            @click="prevPage"
        >
            <span class="arrow arrow-right"></span>
        </div>
        <!---->
        <div
            :class="['next-page__click-button', currentPage >= totalPages ? 'last-page' : '']"
            @click="nextPage"
        >
            <span class="arrow arrow-left"></span>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, watch, defineEmits, defineProps } from 'vue'
import { onClickOutside } from '@vueuse/core'
const target = ref(null)

onClickOutside(target, event => {if(showItemsPerPage.value){showItemsPerPage.value = false}})
const props = defineProps({
    total: {
        type: Number,
        default: 0,
    },
    modelValue: {
        type: Object,
        default: () => ({ page: 1, rowsPerPage: 10 }),
    },
})

const emit = defineEmits(['update:modelValue'])
const itemsPerPageOptions = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
]

const itemsPerPage = ref(10) // 默认每页显示10项

const totalPages = computed(() => Math.ceil(props.total / itemsPerPage.value))

const currentPage = ref(1)
const showItemsPerPage = ref(false)
const prevPage = () => {
    if (currentPage.value > 1) {
        currentPage.value--
    }
    emit('update:modelValue', { page: currentPage.value, rowsPerPage: itemsPerPage.value })
}

const nextPage = () => {
    if (currentPage.value < totalPages.value) {
        currentPage.value++
    }
    emit('update:modelValue', { page: currentPage.value, rowsPerPage: itemsPerPage.value })
}
const selectItemsPerPage = (val) => {
    itemsPerPage.value = val
    showItemsPerPage.value = !showItemsPerPage.value
}
const updateItemsPerPage = (newValue) => {
    itemsPerPage.value = newValue
    currentPage.value = 1 // 重置为第一页
    emit('update:modelValue', { page: currentPage.value, rowsPerPage: itemsPerPage.value })
}
const computedIndex = computed(() => {
    let min = 0
    let max = 0
    min = (currentPage.value - 1) * itemsPerPage.value + 1
    max = Math.min(currentPage.value * itemsPerPage.value, props.total)
    return `${min} – ${max}`
})

watch(itemsPerPage, updateItemsPerPage)
</script>

<style scoped lang="scss">
.pagination-cms {
    color: var(--easy-table-footer-font-color);
    border-top: var(--easy-table-row-border);
    font-size: var(--easy-table-footer-font-size);
    height: var(--easy-table-footer-height);
    padding: var(--easy-table-footer-padding);
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* border-radius: 0px 0px 8px 8px; */
    background-color: var(--white-200) !important;

    .pagination__rows-per-page {
        display: flex;
        align-items: center;
        color: var(--black-200) !important;
        font-weight: 600;
        .pagination__rows-selector {
            display: inline-block;
            min-width: 45px;
            position: relative;
            margin: 0 10px;
            width: var(--easy-table-rows-per-page-selector-width);
            .rows-input__wrapper {
                height: 20px;
                border-bottom: 1px solid var(--easy-table-footer-font-color);
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 5px;
                cursor: pointer;
                .triangle {
                    display: inline-block;
                    vertical-align: middle;
                    width: 0px;
                    height: 0px;
                    border-top: solid 6px var(--easy-table-footer-font-color);
                    border-left: solid 6px transparent;
                    border-right: solid 6px transparent;
                }
            }
            ul.select-items {
                position: absolute;
                top: 20px;
                left: 0px;
                width: 100%;
                margin: 0;
                padding: 0;
                text-align: left;
                list-style-type: none;
                box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;
                z-index: var(--easy-table-rows-per-page-selector-z-index);
                > li {
                    cursor: pointer;
                    padding: var(--easy-table-rows-per-page-selector-option-padding);
                    background-color: var(--easy-table-footer-background-color);
                }
                > li.selected {
                    background: var(--black-300) !important;
                    color: #fff;
                    cursor: pointer;
                    padding: var(--easy-table-rows-per-page-selector-option-padding);
                }
            }
        }
    }

    .pagination__items-index {
        margin: 0 20px 0 10px;
        color: var(--black-200) !important;
        font-weight: 600;
    }
    .previous-page__click-button.first-page,
    .next-page__click-button.last-page {
        cursor: not-allowed;
    }
    .previous-page__click-button,
    .next-page__click-button {
        margin: 0 5px;
        cursor: pointer;
    }
    .arrow {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-top: 2px solid #000;
        border-left: 2px solid #000;
    }
    .arrow-left {
        transform: rotate(135deg);
    }
    .arrow-right {
        transform: rotate(-45deg);
    }
    .first-page,
    .last-page {
        .arrow {
            border-color: #e0e0e0;
        }
    }
}
</style>
