<template>
    <CModal backdrop="static" :visible="commonStore.isModalOpen === true" @close="commonStore.closeModal()">
        <CForm>
            <EditFinancialAssets v-if="commonStore.modalName === 'EditFinancialAssets'" />
            <EditCategories v-if="commonStore.modalName === 'EditCategories'" />
        </CForm>
    </CModal>
</template>

<script>
import { useCommonStore } from '@/stores/common'
import EditFinancialAssets from './EditFinancialAssets'
import EditCategories from './EditCategories'

export default {
    name: 'MainModal',
    components: {
        EditFinancialAssets,
        EditCategories,
    },
    setup() {
        const commonStore = useCommonStore()
        return {
            commonStore,
        }
    },
}
</script>
