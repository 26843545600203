<template>
    <CModalHeader disabled>
        <CModalTitle>Add Categories</CModalTitle>
    </CModalHeader>
    <CModalBody>
        <CFormLabel for="categoryName" class="min-h-[17.5px]">Category Name<span class="text-red-500">*</span></CFormLabel>
        <CFormInput type="text" 
            v-model="inputNameAdd" 
            id="categoryName"
            placeholder="Enter value"
            required="true"
        />
    </CModalBody>
    <CModalFooter>
        <CButton class="btn-action" @click="handleInsertCategory" :disabled="!inputNameAdd.trim()">Create</CButton>
    </CModalFooter>
</template>

<script>
import { useCommonStore } from '@/stores/common'
import { findCategories, insertCategory } from '@/api/categories'

export default {
    name: 'ModalEditCategories',
    setup() {
        const commonStore = useCommonStore()
        return {
            commonStore,
        }
    },
    methods: {
        async handleInsertCategory() {
            if (this.inputNameAdd == '') return
            const id = parseInt(this.$route.params.entityId)

            let body = {
                name: this.inputNameAdd,
                entity_id: id,
            }
            await insertCategory(body)
            await this.fetchCategories(id)
            this.inputNameAdd = ''
            this.commonStore.closeModal()
        },
        async fetchCategories(id) {
            const { data } = await findCategories(id)
            this.commonStore.setDataCategories(data.categories)
        },
    },
    data() {
        return {
            editId: null,
            inputNameAdd: '',
        }
    },
}
</script>
