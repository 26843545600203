export const CONTENT_EVENT_STATUS = {
    DRAFT: 'draft',
    PUBLISHED: 'published',
    ON_GOING: 'on_going',
    UP_COMING: 'up_coming',
    ENDED: 'ended',
}

export const USER_ROLE = {
    ADMIN: 'admin',
    DEVELOPER: 'developer',
    GUEST: 'guest',
    TRADE: 'trade',
    EVENT:'Marketing - Event'
}

export const STATUS_ENUM = [
    {
        value:0,
        name:'inactive'
    },
    {
        value:1,
        name:'active'
    },
]
export  const STATUS_MAP = {
    0:'inactive',
    1:'active'
}
